/* Styling for Inspirational Movie Quotes demo app */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-size: 1.4em;
  background-color: #fcfcfc;
  color: #00008b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
}

a {
  color: #0000ff;
  text-decoration: none;
}

.img_responsive  {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* CSS for footer navigation bar */
#ul-footernav {
  list-style: none;
  overflow:hidden;
  background:#eef3fc;
  margin:0;
  padding:0;
}
#ul-footernav li a {
  text-align:center;
  font-weight: bold;
  color:#2160c4;
  padding:10px;
  width:95px;
}

#ul-footernav li {
  float:left;
}

#ul-footernav li:hover {
  background:#2160c4;
  color: #ff3860;
}
#ul-footernav li:hover a {
  color: #ffffff;
}
#ul-footernav  a {
  display:block;
}

#p-navfooter {
  text-align: center
}

.footernav_outer {
  text-align: center;
}

.footernav_inner {
  display: inline-block;
}


/* Back2School Shopping List */
.styleItem {
  padding-left: 10px;
  padding-right: 3px;
}

.itemBackground{
  background: #fffafa;
  padding: 20px 10px;
}

.itemFrame{
  /* background: #48c774 */
  width: 70vw;
  margin: 10em auto;
  border-radius: 15px;
  padding: 20px 10px;
  color: white;
  border: 3px solid #48c774;
  margin-top: 5px;
  margin-bottom: 5px;
}

.itemStyled{
border: 1px solid white;
border-radius: 3px;
padding: 0.5em;
margin: 0.5em;
}

.itemHeader{
margin: 0.5em;
font-size: 2em;
}

.addItem input[type=text]{
margin: 2.5em 2em;
width: 80%;
outline: none;
border: none;
padding: 0.5em;
}
